import UserService from '../UserService'
export default {
  getEntries ({ commit }) {
    commit('SET_LOADING', true)
    return UserService.list()
      .then(response => {
        commit('SET_USERS', response)
        commit('SET_LOADING', false)
        return Promise.resolve(response)
      })
      .catch((e) => {
        commit('SET_LOADING', false)
        if (e.response && e.response.data && e.response.data.message) {
          return Promise.reject(e.response.data.message)
        } else {
          return Promise.reject(e)
        }
      })
  },
  postEntry ({ commit }, entry) {
    commit('SET_LOADING', true)
    return UserService.post(entry)
      .then(response => {
        commit('SET_USER', response)
        commit('SET_LOADING', false)
        return Promise.resolve(response)
      })
      .catch((e) => {
        commit('SET_LOADING', false)
        if (e.response && e.response.data && e.response.data.message) {
          return Promise.reject(e.response.data.message)
        } else {
          return Promise.reject(e)
        }
      })
  },
  putEntry ({ commit }, entry) {
    commit('SET_LOADING', true)
    return UserService.put(entry)
      .then(response => {
        commit('SET_USER', response)
        commit('SET_LOADING', false)
        return Promise.resolve(response)
      })
      .catch((e) => {
        commit('SET_LOADING', false)
        if (e.response && e.response.data && e.response.data.message) {
          return Promise.reject(e.response.data.message)
        } else {
          return Promise.reject(e)
        }
      })
  },

  loadProfile ({ commit }) {
    commit('SET_LOADING', true)
    return UserService.profile()
      .then(response => {
        commit('SET_PROFILE', response)
        commit('SET_LOADING', false)
        return Promise.resolve(response)
      })
      .catch((e) => {
        commit('SET_LOADING', false)
        if (e.response && e.response.data && e.response.data.message) {
          return Promise.reject(e.response.data.message)
        } else {
          return Promise.reject(e)
        }
      })
  }

}
