
<template lang="pug">
  v-btn.text-none(@click="sendNotification(role)" v-if="currentUserIsAdmin" :color="notificationState===States.SUCCESS?'green':notificationState===States.FAILED?'red':notificationState===States.LOADING?'blue':''" )
    v-icon(small v-if="notificationState===States.SUCCESS").mr-2 fa-check
    v-icon(small v-else-if="notificationState===States.FAILED" ).mr-2 fa-times
    v-icon(small v-else-if="notificationState===States.LOADING" ).mr-2 fa-spinner fa-spin
    v-icon(small v-else).mr-2 fa-paper-plane
    slot

</template>
<script>
import { mapGetters } from 'vuex'
import { currentUserIsAdmin } from '@/modules/Authentication/helper/Checks'
import EventsService from '@/modules/Events/EventsService'
const States = {
  LOADING: 0,
  FAILED: 1,
  SUCCESS: 2
}
export default {
  props: {
    eventId: String,
    role: String
  },
  data: () => ({
    States,
    notificationState: null,
    notificationError: null,
    notificationResult: null
  }),
  name: 'app-event-send-notification-btn',
  created () {

  },
  computed: {
    currentUserIsAdmin,
    ...mapGetters({
    })
  },
  methods: {
    sendEventNotification (role) {
      this.notificationState = States.LOADING
      EventsService.sendNotification(this.eventId, { role })
        .then(response => {
          this.notificationState = States.SUCCESS
          this.notificationResult = response
        })
        .catch(fail => {
          this.notificationState = States.FAILED
          this.notificationError = fail
        })
    },
    sendNotification (role) {
      // reset State
      this.notificationState = null
      this.sendEventNotification(role)
    }
  }
}
</script>
<style scoped>

</style>
