import Vue from 'vue'
import VueI18n from 'vue-i18n'
import _ from 'lodash'

Vue.use(VueI18n)

function getTranslationFiles () {
  return require.context('./modules', true, /index.js$/i)
}

function loadLocaleMessages () {
  const files = getTranslationFiles()
  const messages = {}
  files.keys().forEach(key => {
    if (key.indexOf('/translations/') > -1) { _.merge(messages, files(key)) }
  })
  return messages
}

const datetimeFormats = {
  en: {
    monthYear: {
      year: 'numeric',
      month: 'long'
    },
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric'
    }
  },
  de: {
    monthYear: {
      year: 'numeric',
      month: 'long'
    },
    short: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    }
  }
}
export const locale = window.navigator.language || window.navigator.userLanguage || process.env.VUE_APP_I18N_LOCALE || 'en'
export const i18n = new VueI18n({
  locale: locale,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
  silentTranslationWarn: true, // process.env.NODE_ENV === 'production',
  datetimeFormats
})
