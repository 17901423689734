
export function ActionsFactory (service) {
  const getEntries = ({ commit }, params = {}) => {
    commit('SET_LOADING', true)
    return service.list(params)
      .then(response => {
        commit('SET_ENTITIES', { entities: response, params })
        commit('SET_LOADING', false)
        return Promise.resolve(response)
      })
      .catch((e) => {
        commit('SET_LOADING', false)
        if (e.response && e.response.data && e.response.data.message) {
          return Promise.reject(e.response.data.message)
        } else {
          return Promise.reject(e)
        }
      })
  }

  const getEntry = ({ commit }, ident) => {
    commit('SET_LOADING', true)
    return service.get(ident)
      .then(response => {
        commit('SET_ENTITY', response)
        commit('SET_LOADING', false)
        return Promise.resolve(response)
      })
      .catch((e) => {
        commit('SET_LOADING', false)
        if (e.response && e.response.data && e.response.data.message) {
          return Promise.reject(e.response.data.message)
        } else {
          return Promise.reject(e)
        }
      })
  }

  const postEntry = ({ commit }, entry) => {
    commit('SET_LOADING', true)
    return service.post(entry)
      .then(response => {
        commit('SET_ENTITY', response)
        commit('SET_LOADING', false)
        return Promise.resolve(response)
      })
      .catch((e) => {
        commit('SET_LOADING', false)
        if (e.response && e.response.data && e.response.data.message) {
          return Promise.reject(e.response.data.message)
        } else {
          return Promise.reject(e)
        }
      })
  }

  const putEntry = ({ commit }, entry) => {
    commit('SET_LOADING', true)
    return service.put(entry)
      .then(response => {
        commit('SET_ENTITY', response)
        commit('SET_LOADING', false)
        return Promise.resolve(response)
      })
      .catch((e) => {
        commit('SET_LOADING', false)
        if (e.response && e.response.data && e.response.data.message) {
          return Promise.reject(e.response.data.message)
        } else {
          return Promise.reject(e)
        }
      })
  }

  const deleteEntry = ({ commit }, entry) => {
    commit('SET_LOADING', true)
    return service.delete(entry)
      .then(response => {
        commit('DELETE_ENTRY', entry)

        commit('SET_LOADING', false)
        return Promise.resolve(response)
      })
      .catch((e) => {
        commit('SET_LOADING', false)
        if (e.response && e.response.data && e.response.data.message) {
          return Promise.reject(e.response.data.message)
        } else {
          return Promise.reject(e)
        }
      })
  }
  return { deleteEntry, postEntry, putEntry, getEntries, getEntry }
}
