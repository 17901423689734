
export default [
  {
    path: '/users',
    name: 'UsersList',
    component: () => import(/* webpackChunkName: "users" */ './views/List'),
    meta: {
      requiredRole: 'ROLE_USER_MANAGE'
    }
  },
  {
    path: '/users/new',
    name: 'UsersCreate',
    props: true,
    component: () => import(/* webpackChunkName: "users" */ './views/Create'),
    meta: {
      requiredRole: 'ROLE_USER_CREATE'
    }
  },
  {
    path: '/users/:userId',
    name: 'UsersEdit',
    props: true,
    component: () => import(/* webpackChunkName: "users" */ './views/Edit'),
    meta: {
      requiredRole: 'ROLE_USER_UPDATE'
    }
  }
]
