import { marked } from 'marked'

export default {
  _Vue: null,
  timer: null,
  _options: {},
  install (Vue, options) {
    marked.setOptions({
      // renderer: new marked.Renderer(),
      gfm: true,
      breaks: true
    })
    Vue.prototype.marked = marked
  }
}
