import defaultState from './default'
import Vue from 'vue'
import { keyBy } from 'lodash'
const STATE_KEY = 'entities'
const KEY = '@id'
export default {
  SET_LOADING (state, flag) {
    state.loading = flag
  },
  SET_ENTITIES (state, { entities, params }) {
    Vue.set(state, STATE_KEY, keyBy(entities, KEY))
  },
  SET_ENTITY (state, entry) {
    // console.log('vue.set', state[STATE_KEY], entry[KEY], entry)
    Vue.set(state[STATE_KEY], entry[KEY], entry)
  },
  DELETE_ENTRY (state, entry) {
    Vue.delete(state[STATE_KEY], entry[KEY])
  },
  RESET_STORAGE (state) {
    for (const key in defaultState) {
      state[key] = defaultState[key]
    }
  }
}
