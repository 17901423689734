export const assignmentTypes = [
  'Drums',
  'Bass',
  'Guitar',
  'Sax',
  'Keys',
  'Backing Vocals',
  'Produktionsleitung',
  'Tontechniker',
  'Lichttechniker',
  'Licht- & Tontechnik',
  'Bühnentechniker',
  'Sonstiges'
]
