export const getMembers = (response) => {
  if (response['@type'] !== 'hydra:Collection') return null
  if (response['@type'] !== 'hydra:Collection') return null
  if (!response['hydra:member'] || !response['hydra:member'][0]) return null
  return response['hydra:member']
}
export const getFirstMember = (response) => {
  return getMembers(response)[0]
}
export const extractIriOrId = (data) => {
  return extractIri(data) || getIdFromIri(data)
}
export const extractIri = (data) => {
  if (!data) { return null }
  if (data['@id']) {
    return data['@id']
  } else if (typeof data === typeof 'string') {
    return data
  } else {
    // console.log(data)
    return null
  }
}
export const extractId = (data) => {
  // console.log(data)
  if (typeof data === typeof 42) {
    return data
  }
  if (data.id) {
    return +data.id
  }
  const iri = extractIri(data)
  return getIdFromIri(iri)
}

export const idOrIriOrObjectAreEqual = (elementOne, elementTwo) => {
  if (!elementOne || !elementTwo) { return false }
  if (typeof elementOne === typeof []) {
    return elementOne.some(e1 => idOrIriOrObjectAreEqual(e1, elementTwo))
  } else if (typeof elementTwo === typeof []) {
    return elementTwo.some(e2 => idOrIriOrObjectAreEqual(elementOne, e2))
  }
  const iri1 = extractIriOrId(elementOne)
  const iri2 = extractIriOrId(elementTwo)
  if (!iri1 || !iri2) {
    // console.log(elementOne, elementTwo)
    throw new Error('could not find iri or ID')
  }
  if (!!iri1 && !!iri2) {
    return iri1 === iri2 || getIdFromIri(iri1) === getIdFromIri(iri2)
  }
}
export const getIdFromIri = (iri) => {
  if (!iri) { return null }
  if (typeof iri === typeof 42) {
    return iri
  } else if (iri.indexOf('/') <= -1) {
    return parseInt(iri, 10)
  }
  const parts = iri.split('/')
  return parseInt(parts[parts.length - 1], 10)
}
