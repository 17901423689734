import { extractIri } from '@/modules/Core/helpers/HydraHelper'

export const updateEntities = (newEntities, oldEntities = [], filter = () => true) => {
  if (!newEntities) return []
  if (oldEntities.length <= 0) { return [...newEntities] }
  const newEntitiesIris = newEntities.map(extractIri)
  const relevantEntities = oldEntities.filter(filter)
  const relevantEntitiesIris = relevantEntities.map(extractIri)
  const resultsEntities = [...oldEntities]
  const deleteEntities = relevantEntities.filter(e => newEntitiesIris.indexOf(extractIri(e)) <= -1)
  const addEntities = newEntities.filter(e => relevantEntitiesIris.indexOf(extractIri(e)) <= -1)
  const updateEntities = relevantEntities.filter(e => newEntitiesIris.indexOf(extractIri(e)) > -1)
  // console.log({
  //   relevantEntitiesIris,
  //   newEntities,
  //   oldEntities,
  //   deleteEntities,
  //   addEntities,
  //   updateEntities
  // })
  for (const deleteEntity of deleteEntities) {
    const i = resultsEntities.findIndex(e => extractIri(e) === extractIri(deleteEntity))
    delete resultsEntities[i]
  }
  for (const updateEntity of updateEntities) {
    const i = resultsEntities.findIndex(e => extractIri(e) === extractIri(updateEntity))
    resultsEntities[i] = newEntities.find(e => extractIri(e) === extractIri(updateEntity))
  }
  for (const addEntity of addEntities) {
    resultsEntities.push(addEntity)
  }
  return resultsEntities
}
