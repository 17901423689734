export const convertParamsToQuery = (params = {}) => {
  if (Object.keys(params).length <= 0) return ''
  const result = []
  for (const key of Object.keys(params)) {
    if (typeof params[key] === typeof []) {
      for (const value of params[key]) {
        result.push([key, value].join('[]='))
      }
    } else {
      result.push([key, params[key]].join('='))
    }
  }
  return '?' + result.join('&')
}

export const getFullPathURLByIri = (iri) => {
  // console.log(process.env)
  const baseURL = process.env.VUE_APP_API_BASE_URL
  const partsOfBaseUrl = baseURL.split('/')
  const partsOfIri = iri.split('/')
  let result = null
  for (const part of partsOfBaseUrl) {
    const index = partsOfIri.indexOf(part)
    if (index > -1) {
      result = partsOfBaseUrl.slice(0, partsOfBaseUrl.indexOf(part))
      // console.log(result)
      result.push(...partsOfIri.slice(index))
    }
  }

  return result.join('/')
}
