import defaultState from './default'
import Vue from 'vue'
import { keyBy } from 'lodash'

export default {
  SET_LOADING (state, flag) {
    state.loading = flag
  },
  SET_USERS (state, entries) {
    Vue.set(state, 'users', keyBy(entries, 'id'))
  },
  SET_USER (state, entry) {
    Vue.set(state.users, entry.id, entry)
  },
  SET_PROFILE (state, profileOfLoggedInUser) {
    if (profileOfLoggedInUser !== null) {
      state.loggedInUser = {
        ...profileOfLoggedInUser,
        expireTime: new Date(profileOfLoggedInUser.exp * 1000)
      }
    } else { state.loggedInUser = null }
  },
  RESET_STORAGE (state) {
    for (const key in defaultState) {
      state[key] = defaultState[key]
    }
  }
}
