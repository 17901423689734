import defaultState from './default'
import Vue from 'vue'
import { keyBy } from 'lodash'
const STATE_KEY = 'documents'
const KEY = '@id'
export default {
  SET_LOADING (state, flag) {
    state.loading = flag
  },
  SET_ENTITIES (state, { entities, params }) {
    const listToDeleteIds = []
    const listOfEntities = Object.values(state[STATE_KEY]) || []
    if (params && params.event) {
      listToDeleteIds.push(...listOfEntities.filter(e => e.event && e.event.indexOf(params.event + '') > -1).map(e => e['@id']))
    }
    if (params && params.assignment && typeof params.assignment === typeof '') {
      listToDeleteIds.push(...listOfEntities.filter(e => e.assignment && e.assignment.indexOf(params.assignment + '') > -1).map(e => e['@id']))
    } else if (typeof params.assignment === typeof []) {
      for (const assignment of params.assignment) {
        listToDeleteIds.push(...listOfEntities.filter(e => e.assignment && e.assignment.indexOf(assignment + '') > -1).map(e => e['@id']))
      }
    }
    if (Object.keys(params).length <= 0) { // delete all
      listToDeleteIds.push(...listOfEntities.map(e => e['@id']))
    }

    const newList = listOfEntities.filter(e => listToDeleteIds.indexOf(e['@id']) <= -1)
    if (entities) {
      newList.push(...entities)
    }
    Vue.set(state, STATE_KEY, keyBy(newList, KEY) || {})
  },
  SET_ENTITY (state, entry) {
    Vue.set(state[STATE_KEY], entry[KEY], entry)
  },
  DELETE_ENTRY (state, entry) {
    Vue.delete(state[STATE_KEY], entry[KEY])
  },
  RESET_STORAGE (state) {
    for (const key in defaultState) {
      state[key] = defaultState[key]
    }
  }
}
