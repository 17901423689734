import { clone } from 'lodash'
import store from '@/store'
import { getIdFromIri } from '@/modules/Core/helpers/HydraHelper'

export default {
  isLoading: (state) => state.loading,
  asArray: (state) => Object.values(state.documents),
  detail: (state) => (idOrIri) => state.documents[idOrIri] || Object.values(state.documents).find(e => getIdFromIri(e['@id']) + '' === idOrIri + ''),
  byId: (state) => (id) => state.documents[id],
  byAssignment: (state) => (assignment) => Object.values(state.documents).filter(e => e.assignment === assignment['@id']),
  byEvent: (state) => (event) => Object.values(state.documents).filter(e => e.event === event['@id']),
  byEventId: (state) => (event) => Object.values(state.documents).filter(e => e.event && e.event.split('/').indexOf(event + '') > -1),
  eventWithAssignments: (state) => (id) => {
    const obj = clone(state.documents[id])
    obj.assignments = store.getters['assignments/byEvent'](obj)
    return obj
  }
}
