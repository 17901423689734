import Service from '../AssigmentService'
import { ActionsFactory } from '@/modules/Core/helpers/ActionsFactory'
const baseActionsInstance = ActionsFactory(Service)
export default {
  getEntries: baseActionsInstance.getEntries,
  getEntry: baseActionsInstance.getEntry,
  postEntry: baseActionsInstance.postEntry,
  putEntry: baseActionsInstance.putEntry,
  deleteEntry: baseActionsInstance.deleteEntry,

  confirm ({ commit }, entry) {
    commit('SET_LOADING', true)
    return Service.confirm(entry)
      .then(response => {
        commit('SET_ENTITY', response)
        commit('SET_LOADING', false)
        return Promise.resolve(response)
      })
      .catch((e) => {
        commit('SET_LOADING', false)
        if (e.response && e.response.data && e.response.data.message) {
          return Promise.reject(e.response.data.message)
        } else {
          return Promise.reject(e)
        }
      })
  },
  decline ({ commit }, entry) {
    commit('SET_LOADING', true)
    return Service.decline(entry)
      .then(response => {
        commit('SET_ENTITY', response)
        commit('SET_LOADING', false)
        return Promise.resolve(response)
      })
      .catch((e) => {
        commit('SET_LOADING', false)
        if (e.response && e.response.data && e.response.data.message) {
          return Promise.reject(e.response.data.message)
        } else {
          return Promise.reject(e)
        }
      })
  },
  unset ({ commit }, entry) {
    commit('SET_LOADING', true)
    return Service.unset(entry)
      .then(response => {
        commit('SET_ENTITY', response)
        commit('SET_LOADING', false)
        return Promise.resolve(response)
      })
      .catch((e) => {
        commit('SET_LOADING', false)
        if (e.response && e.response.data && e.response.data.message) {
          return Promise.reject(e.response.data.message)
        } else {
          return Promise.reject(e)
        }
      })
  }
}
