import ApiClient from './ApiClient.js'

export default new ApiClient({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    'content-type': 'application/ld+json',
    Accept: 'application/ld+json'

  }
})
