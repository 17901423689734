import { isLoggedIn, isPublicPage, isSessionExpired } from './Checks'
import { loginRoute } from './Consts'

export const RouterCheck = (to, from, next) => {
  // console.log('RouterCheck', from, to, isPublicPage(to), isLoggedIn(), isSessionExpired(), routeHasRight(to))
  if (isPublicPage(to)) return next()
  if (!isLoggedIn()) {
    loginRoute.params = { nextUrl: to.fullPath, error: 'NOT_LOGGED_IN' }
    return next(loginRoute)
  }
  if (isSessionExpired()) {
    loginRoute.params = { nextUrl: to.fullPath, error: 'SESSION_EXPIRED' }
    return next(loginRoute)
  }
  next()
}
