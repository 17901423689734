<template lang="pug">
  v-app(app)
    app-app-bar(v-if="!hideNavigation")
    v-main.main
      v-row(v-if="!hasRight($route)")
        v-col.ma-4
          v-alert( type="info" ) Sie haben keine Rechte diese Seite zu betreten!
            v-btn(:to="{name:'EventsList'}").mx-3.text-none zurück zu Veranstaltungen
      router-view(v-else)

</template>

<script>

import AppAppBar from '@/modules/Core/components/app-app-bar'
import { isPublicPage, routeHasRight } from '@/modules/Authentication/helper/Checks'

export default {
  name: 'App',
  components: { AppAppBar },
  metaInfo: {
    title: 'Home',
    titleTemplate: 'Mr. Rod Buchungssystem: %s',
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    ]
  },
  data: () => ({
    //
  }),
  mounted () {
  },
  computed: {
    hideNavigation () {
      if (!this.$route) return false
      if (!this.$route.meta) return false
      return this.$route.meta.hideNavigation
    }
  },
  methods: {
    hasRight (to) {
      return isPublicPage(to) || routeHasRight(to)
    }

  }

}
</script>

<style>
.main{
  background-image: url("./assets/main-bg.png");
  background-repeat: repeat;
}
.text-underline{
  text-decoration: underline;
  text-decoration-style: dotted;
}

</style>
