import AuthenticationService from '../AuthenticationService'
import store from '../../../store'
import BackendService from '@/modules/Core/services/BackendService'
export default {
  login ({ commit, dispatch }, { email, password }) {
    commit('SET_LOADING', true)
    return AuthenticationService.login(email, password)
      .then(response => {
        commit('LOGIN_SUCCESS')
        commit('SET_LOADING', false)
        return store.dispatch('users/loadProfile')
      })
      .catch((e) => {
        console.log('FAIL', { e })
        commit('LOGIN_FAILED')
        commit('SET_LOADING', false)
        if (e.response && e.response.data && e.response.data.message) {
          // eslint-disable-next-line prefer-promise-reject-errors
          if (e.response.data.message === 'Invalid credentials.') return Promise.reject('INVALID_CREDENTIALS')
          // eslint-disable-next-line prefer-promise-reject-errors
          if (e.response.data.message === 'Your user account no longer active.') return Promise.reject('NO_LONGER_ACTIVE')
          return Promise.reject(e.response.data.message)
        } else {
          return Promise.reject(e)
        }
      })
  },
  startImpersonate ({ commit, dispatch }, user) {
    commit('START_IMPERSONATE', user)
    store.dispatch('users/loadProfile')
  },
  endImpersonation ({ commit }) {
    BackendService.unsetHeader('X-Switch-User')
    store.dispatch('users/loadProfile').then(() => {
      commit('STOP_IMPERSONATE')
    })
  },
  logout ({ commit }) {
    commit('LOGOUT_SUCCESS')
    store.dispatch('resetStorage')
  }
}
