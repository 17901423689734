import BackendService from '../Core/services/BackendService'
import { getFirstMember, getMembers } from '@/modules/Core/helpers/HydraHelper'
const baseURL = '/users'
export default {
  profile: () => BackendService.get(`${baseURL}/profile`).then(r => getFirstMember(r.data)).catch(e => Promise.reject(e)),
  list: () => BackendService.get(`${baseURL}`).then(r => getMembers(r.data)).catch(e => Promise.reject(e)),
  get: (id) => BackendService.get(`${baseURL}/${id}`).then(r => r.data).catch(e => Promise.reject(e)),
  post: (data) => BackendService.post(`${baseURL}`, data).then(r => r.data).catch(e => Promise.reject(e)),
  put: (data) => BackendService.put(`${baseURL}/${data.id}`, data).then(r => r.data).catch(e => Promise.reject(e))
}
