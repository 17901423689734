import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import marked from './plugins/marked'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@fortawesome/fontawesome-free/css/all.css'
import 'vue-awesome-notifications/dist/styles/style.css'
import VueAWN from 'vue-awesome-notifications'
import VueMask from 'v-mask'
import VueMeta from 'vue-meta'

import { i18n } from './i18n'

import BackendService from './modules/Core/services/BackendService.js'
import authenticationPlugin from './modules/Authentication/plugin.js'

import { bytes } from '@/modules/Core/filter/Bytes'
import { shortGermanDate } from '@/modules/Core/filter/dateFilter'
import { nl2Comma } from '@/modules/Core/filter/nl2Comma'

Vue.filter('bytes', bytes)
Vue.filter('shortGermanDate', shortGermanDate)
Vue.filter('nl2Comma', nl2Comma)
Vue.config.productionTip = false
Vue.use(BackendService, {})
Vue.use(marked)
Vue.use(VueAWN, { })
Vue.use(authenticationPlugin)
Vue.use(VueMask)
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})
new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
