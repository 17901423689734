import BackendService from '../Core/services/BackendService'
import { getMembers } from '@/modules/Core/helpers/HydraHelper'
import { convertParamsToQuery } from '@/modules/Core/helpers/RequestHelper'
const baseURL = '/events'
export default {
  list: (params) => BackendService.get(`${baseURL}` + convertParamsToQuery(params)).then(r => getMembers(r.data)).catch(e => Promise.reject(e)),
  get: (id) => BackendService.get(`${baseURL}/${id}`).then(r => r.data).catch(e => Promise.reject(e)),
  post: (payload) => BackendService.post(`${baseURL}`, payload).then(r => r.data).catch(e => Promise.reject(e)),
  put: (payload) => BackendService.put(`${baseURL}/${payload.id}`, payload).then(r => r.data).catch(e => Promise.reject(e)),
  delete: (payload) => BackendService.delete(`${baseURL}/${payload.id}`).then(r => r.data).catch(e => Promise.reject(e)),
  sendNotification: (id, payload) => BackendService.put(`${baseURL}/${id}/notification`, payload).then(r => r.data).catch(e => Promise.reject(e))
}
