<template lang="pug">
  .wrapper
    v-app-bar(app dark v-if="profile" height="150" ).pa-0
      .d-flex.align-center
          v-img(
            alt="Mr. Rod Logo"
            contain
            src="@/assets/mister-rod-no1_old.png"
            transition="scale-transition"
            width="180"
          ).shrink

      v-spacer
      .d-flex.align-center
        v-btn(
          height="64"
          :to="{name:'EventsList'}"
          text
        ).text-none.menu-item
          span {{$t('events.heading')}}
        v-btn(
          height="64"
          :to="{name:'UsersList'}"
          text
          v-if="hasRole('ROLE_ADMIN')"
        ).text-none.menu-item
          span {{$t('users.heading')}}

      v-spacer
      .d-flex.align-center
        v-menu(offset-y)
          template(v-slot:activator="{ on, attrs }")
            v-btn(height="64" v-bind="attrs" v-on="on" text)
              v-icon fa-user
          v-list
            v-list-item
              v-list-item-title {{$t('auth.loggedInAs')}}:
                br
                strong {{profile.username}}
            v-list-item
              v-list-item-title {{$t('auth.sessionTime')}}
                br
                strong {{time}}
            v-list-item
              v-list-item-title App-Version
                br
                strong v{{VUE_APP_VERSION}}
            v-list-item
              v-list-item-title
                v-btn(text @click="$logout()")
                  v-icon(small).mr-2 fa-sign-out-alt
                  | {{$t('auth.logout')}}
      .impersonationBar(v-if="impersonatedUser" @click="stopImpersonating()") Ansicht wie Nutzer "{{impersonatedUser.fullname}}"
        span().text-none.mx-2 (zum Deaktivieren klicken)
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { getDiffOfDates } from '@/modules/Core/helpers/Duration'
import { hasRole, isLoggedInAndSessionNotExpired } from '@/modules/Authentication/helper/Checks'
import BackendService from '@/modules/Core/services/BackendService'

export default {
  name: 'app-app-bar',
  data () { return { currentTime: new Date(), VUE_APP_VERSION: process.env.VUE_APP_VERSION } },
  mounted () {
    if (isLoggedInAndSessionNotExpired()) {
      this.loadProfile().then(data => {
        if (!data || !data.exp) {
          this.$logout(null)
        }
      }).catch(e => {
        this.$logout(null)
      })
    }
    if (this.impersonatedUser) {
      BackendService.setHeader('X-Switch-User', this.impersonatedUser.email)
      this.startImpersonating(this.impersonatedUser)
    }
    const vm = this
    setInterval(() => { vm.updateTimer() }, 1000)
  },
  watch: {
    impersonatedUser (value) {
      if (value) {
        BackendService.setHeader('X-Switch-User', value.email)
        this.startImpersonating(value)
      }
    }
  },
  methods: {
    ...mapActions({
      loadProfile: 'users/loadProfile',
      stopImpersonating: 'authentication/endImpersonation',
      startImpersonating: 'authentication/startImpersonate'
    }),
    updateTimer () {
      this.$set(this, 'currentTime', new Date())
    },
    hasRole: hasRole
  },
  computed: {
    ...mapGetters({
      profile: 'users/profile',
      impersonatedUser: 'authentication/impersonatedUser'
    }),
    time () {
      if (!this.profile.expireTime) return ''
      if (!this.currentTime) return ''
      const duration = getDiffOfDates(this.currentTime, this.profile.expireTime)
      if (duration.minute > 0) return ` ${duration.minute}m ${duration.second}s`
      else return ` ${duration.second}s`
    }
  }
}
</script>

<style scoped lang="less">

.v-btn.menu-item{
  border-radius: 0;
  background: #272727;
  &.v-btn--active{
    border-bottom: lightgray 5px solid;
    padding-top: 5px;
    background-color: transparent !important;
  }
  &:before {
    background-color: transparent !important;
  }
}
.impersonationBar{
  cursor: pointer;
  background: yellow;
  color: black;
  position: absolute;
  top:150px;
  left:50%;
  transform: translateX(-50%);
  height: auto;
  z-index: 10;
  text-align: center;
  padding-right: 40px;
  padding-left: 40px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
</style>
