import { extractId, extractIri } from '@/modules/Core/helpers/HydraHelper'

export default {
  isLoading: (state) => state.loading,
  asArray: (state) => Object.values(state.assignments),
  detail: (state) => (idOrIri) => state.assignments[idOrIri] || Object.values(state.assignments).find(e => extractId(e) === extractId(idOrIri)),
  byIri: (state) => (iri) => state.assignments[iri] || null,
  byId: (state) => (iri) => Object.values(state.assignments).find(e => extractId(e) === extractId(iri)),
  byEvent: (state) => (event) => Object.values(state.assignments).filter(e => e.event === extractIri(event)),
  byEventId: (state) => (eventId) => Object.values(state.assignments).filter(e => extractId(e.event) === +eventId)
}
