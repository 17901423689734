<template lang="pug">
  v-list-item(two-line v-if="user").mouse-hover
    v-list-item-content
      v-list-item-title
        v-container
          v-row
            v-col
              v-row
                span {{user.fullname?user.fullname:user.username}}
                v-chip(v-if="loggedInUser.id === user.id" color="blue" x-small).mx-2 {{$t('events.thisIsYou')}}

                //template(v-if="currentUserIsAdmin()")
                  v-icon(:color="assignment.confirmed?'success':'grey lighten-2'" @click="confirm").ml-3 {{assignment.confirmed?'fa-check-circle':'far fa-check-circle'}}
              v-row
                small {{assignment.type}}
            v-col(:class="{'red-border':!currentUserIsAdmin() && assignment.confirmed === 'unset' && loggedInUser.id === user.id}").text-center
              v-item-group(v-model="assignment.confirmed" dense ).ml-4
                v-item(value='confirmed' ).mx-2
                  v-btn(@click="confirmationChanged('confirmed')" :class="{disabled:!(currentUserIsAdmin() || (loggedInUser.id === user.id && assignment.confirmed === 'unset' )) }" text icon)
                    v-icon(:color="assignment.confirmed === 'confirmed'?'success':'grey lighten-2'"  ) fa-check
                v-item(value='declined' ).mx-2
                  v-btn(@click="confirmationChanged('declined')" :class="{disabled:!(currentUserIsAdmin() || (loggedInUser.id === user.id && assignment.confirmed === 'unset'))}" text icon)
                    v-icon(:color="assignment.confirmed === 'declined'?'error':'grey lighten-2'") fa-times
                v-item(value='unset' v-if="currentUserIsAdmin()").mx-2
                  v-btn( @click="confirmationChanged('unset')" :class="{disabled:!(currentUserIsAdmin() || (loggedInUser.id === user.id && assignment.confirmed === 'unset'))}" text  icon)
                    v-icon(:color="assignment.confirmed === 'unset'?'info':'grey lighten-2'" ) fa-question
              span.red--text(v-if="!currentUserIsAdmin() && assignment.confirmed === 'unset' && loggedInUser.id === user.id") Bitte entscheiden.
            v-col.text-right(v-if="!short || currentUserIsAdmin()")
              v-btn(v-if="currentUserIsAdmin() || loggedInUser.id === user.id"  :to="{name:'EventsListAssignment', params: {eventId:extractId(assignment.event)+'', assignmentId: assignment.id}}" text).text-none {{assignment.description?'Notiz &':''}} {{documents && documents.length>0?documents.length:'keine '}} {{$t('events.documents')}}
    v-list-item-action(v-if="!short || currentUserIsAdmin()")
      v-dialog(transition='dialog-top-transition' max-width='600' v-if="hasRole('ROLE_ASSIGNMENT_DELETE') ")
        template(v-slot:activator='{ on, attrs }')
          v-btn(icon v-bind='attrs' v-on='on' color="error" )
            v-icon fa-trash
        template(v-slot:default='dialog')
          v-card()
            v-toolbar(color='error')
              h3 {{$t('core.sure')}}
            v-card-actions.justify-end
              v-btn(@click='dialog.value = false; deleteEntry()' color="error")
                v-icon.mr-2 fa-trash
                | {{$t('core.delete')}}
              v-btn(@click="dialog.value = false;" text )
                v-icon.mr-2 fa-times
                | {{$t('core.cancel')}}
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { hasRole, currentUserIsAdmin } from '@/modules/Authentication/helper/Checks'
import { assignmentTypes } from '@/consts'
import { extractId } from '@/modules/Core/helpers/HydraHelper'
export default {
  name: 'app-events-assignment-line',
  data () {
    return {
      assignmentTypes,
      localAssignment: {},
      confirmed: this.assignment.confirmed
    }
  },
  props: {
    event: Object,
    assignment: Object,
    short: Boolean

  },
  mounted () { },
  computed: {
    ...mapGetters({
      getUser: 'users/byIRI',
      loggedInUser: 'users/profile',
      getDocuments: 'documents/byAssignment'
    }),
    user () { return this.getUser(this.assignment.user) },
    documents () { return this.getDocuments(this.assignment) }
  },
  watch: {
  },
  methods: {
    ...mapActions({
      putAssignment: 'assignments/putEntry',
      deleteAssignment: 'assignments/deleteEntry',
      confirmAssignment: 'assignments/confirm',
      declineAssignment: 'assignments/decline',
      unsetAssignment: 'assignments/unset'
    }),
    currentUserIsAdmin,
    extractId,
    confirmationChanged (value) {
      if (!(currentUserIsAdmin() || (this.loggedInUser.id === this.user.id && this.assignment.confirmed === 'unset'))) {
        return false
      }
      switch (value) {
        case 'confirmed': this.confirmAssignment(this.assignment); break
        case 'declined': this.declineAssignment(this.assignment); break
        case 'unset': this.unsetAssignment(this.assignment).then(() => {
          this.$awn.success('Einladung erfolgreich erneut verschickt.')
        }); break
      }
    },
    hasRole,
    deleteEntry () {
      this.deleteAssignment(this.assignment)
    }
  }
}
</script>

<style scoped lang="less">
.red-border{
  border-radius: 5px;
  border: red solid thin;
}
.mouse-hover:hover{
  background-color: lighten(black, 85%);
}
.disabled{
  cursor: not-allowed;

}
</style>
