
export default [
  {
    path: '/events',
    name: 'EventsList',
    component: () => import(/* webpackChunkName: "events" */ './views/List'),
    meta: {
      requiredRole: 'ROLE_EVENT_LIST'
    }
  }, {
    path: '/events/new',
    name: 'EventCreate',
    component: () => import(/* webpackChunkName: "events" */ './views/Create'),
    meta: {
      requiredRole: 'ROLE_EVENT_CREATE'
    }
  }, {
    path: '/events/:eventId',
    name: 'EventsDetail',
    props: true,
    component: () => import(/* webpackChunkName: "events" */ './views/Detail'),
    meta: {
      requiredRole: 'ROLE_EVENT_SHOW'
    },
    children: [{
      path: 'assignment/new',
      name: 'EventsListNewAssignment',
      props: true,
      component: () => import(/* webpackChunkName: "events" */ './views/NewAssignment'),
      meta: {
        requiredRole: 'ROLE_ASSIGNMENT_CREATE'
      }
    }, {
      path: 'assignment/:assignmentId',
      name: 'EventsListAssignment',
      props: true,
      component: () => import(/* webpackChunkName: "events" */ './views/Assignment'),
      meta: {
        requiredRole: 'ROLE_EVENT_SHOW'
      }
    }]
  }, {
    path: '/events/:eventId/edit',
    name: 'EventsEdit',
    props: true,
    component: () => import(/* webpackChunkName: "events" */ './views/Edit'),
    meta: {
      requiredRole: 'ROLE_EVENT_UPDATE'
    }
  }
]
