import defaultState from './default'
import Vue from 'vue'

export default {
  SET_LOADING (state, flag) {
    state.loading = flag
  },
  LOGIN_SUCCESS (state) {
    state.isLoggedIn = true
  },
  LOGIN_FAILED (state) {
    state.isLoggedIn = false
  },
  START_IMPERSONATE (state, user) {
    state.impersonatedUser = user
  },
  STOP_IMPERSONATE (state) {
    Vue.set(state, 'impersonatedUser', null)
  },
  LOGOUT_SUCCESS (state) {
    state.isLoggedIn = false
  },
  RESET_STORAGE (state) {
    for (const key in defaultState) {
      state[key] = defaultState[key]
    }
  }
}
