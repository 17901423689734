import Vue from 'vue'
import VueRouter from 'vue-router'

import authenticationRoutes from './modules/Authentication/routes'
import eventsRoutes from './modules/Events/routes'
import userRoutes from './modules/Users/routes'

Vue.use(VueRouter)

const fallbackRoute = [{ path: '*', redirect: '/events' }]

const routes = []
  .concat(authenticationRoutes)
  .concat(eventsRoutes)
  .concat(userRoutes)
  .concat(fallbackRoute)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
