import router from '../../router'
import store from '../../store'
import { RouterCheck } from './helper/RouterCheck'
import { loginRoute } from '@/modules/Authentication/helper/Consts'
import CookieService from '@/modules/Core/services/CookieService'
import { getFirstMember } from '@/modules/Core/helpers/HydraHelper'
import { deleteCookies } from '@/modules/Authentication/helper/Cookies'
import AppEventsAssignmentLine from '@/modules/Assigments/components/app-events-assignment-line.vue'
import AppEventSendNotificationBtn from '@/modules/Events/components/app-event-send-notification-btn.vue'

const AuthPlugin = {
  components: { AppEventSendNotificationBtn, AppEventsAssignmentLine },
  _Vue: null,
  timer: null,
  _options: {},
  install (Vue, options) {
    this._Vue = Vue
    this._options = options
    this._Vue.prototype.$auth = AuthPlugin
    this._Vue.prototype.$logout = this.logout
    this._installInterceptors()
    router.beforeEach(RouterCheck)
  },
  logout (reason = 'LOGOUT') {
    deleteCookies()
    store.dispatch('authentication/logout')
    if (reason !== 'LOGOUT') {
      loginRoute.params = { error: reason }
    } else {
      loginRoute.params = { success: 'logoutSuccess' }
    }
    CookieService.deleteCookies()

    if (router.currentRoute.name !== loginRoute.name) { router.push(loginRoute) }
  },
  _installInterceptors: function () {
    this._Vue.prototype.$http.axios.interceptors.request.use(this._requestInterceptor, this._requestInterceptorError)
    this._Vue.prototype.$http.axios.interceptors.response.use(this._responseInterceptor, this._responseInterceptorError)
  },
  _requestInterceptorError: (error) => Promise.reject(error),
  _requestInterceptor: (config) => config,
  _responseInterceptor: (data) => {
    if (data.request.responseURL.endsWith('/api/users/profile')) {
      const dataElement = getFirstMember(data.data)
      const ms = new Date(dataElement.exp * 1000).getTime() - new Date().getTime()
      setTimeout(() => {
        AuthPlugin.logout('SESSION_EXPIRED')
      }, ms)
    }
    return data
  },
  _responseInterceptorError: (error) => {
    if (error.response.status === 401) {
      AuthPlugin.logout('NOT_LOGGED_IN')
    }
    return Promise.reject(error)
  }

}

export default AuthPlugin
