export const getDiffOfDates = (date1, date2) => {
  const durationMS = date2.valueOf() - date1.valueOf() // The unit is millisecond
  const hours = parseInt(durationMS / (60 * 60 * 1000)) // Turn the duration into hour format
  const minutes = parseInt(durationMS / (60 * 1000)) // Turn the duration into hour format
  const seconds = parseInt(durationMS / (1000)) // Turn the duration into hour format
  let differenceMsTemp = durationMS
  // take out milliseconds
  differenceMsTemp = differenceMsTemp / 1000
  const second = Math.floor(differenceMsTemp % 60)
  differenceMsTemp = differenceMsTemp / 60
  const minute = Math.floor(differenceMsTemp % 60)
  differenceMsTemp = differenceMsTemp / 60
  const hour = Math.floor(differenceMsTemp % 24)
  const day = Math.floor(differenceMsTemp / 24)
  return { second, seconds, minute, minutes, hour, hours, day }
}
