import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

// import bots from './modules/Bots/store'
import authentication from './modules/Authentication/store'
import users from './modules/Users/store'
import events from './modules/Events/store'
import assignments from './modules/Assigments/store'
import documents from './modules/Documents/store'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const plugins = debug ? [createPersistedState({ key: 'mr-rod-frontend' })] : []
export default new Vuex.Store({
  modules: { authentication, users, events, assignments, documents },
  plugins,
  strict: debug,
  actions: {
    resetStorage ({ commit, state }) {
      for (const key in state) {
        commit(`${key}/RESET_STORAGE`)
      }
    }
  }
})
