import { clone } from 'lodash'
import store from '@/store'
import { getIdFromIri } from '@/modules/Core/helpers/HydraHelper'
const STATE_KEY = 'entities'

export default {
  isLoading: (state) => state.loading,
  asArray: (state) => Object.values(state[STATE_KEY]),
  detail: (state) => (idOrIri) => state[STATE_KEY][idOrIri] || Object.values(state[STATE_KEY]).find(e => getIdFromIri(e['@id']) + '' === idOrIri + ''),
  byIri: (state) => (iri) => state[STATE_KEY][iri],
  byId: (state) => (id) => Object.values(state[STATE_KEY]).find(e => getIdFromIri(e['@id']) + '' === id + ''),
  eventWithAssignments: (state) => (id) => {
    const obj = clone(state[STATE_KEY][id])
    if (!obj) return null
    obj.assignments = store.getters['assignments/byEvent'](obj)
    return obj
  }
}
