import Service from '../EventsService'
import { ActionsFactory } from '@/modules/Core/helpers/ActionsFactory'
const baseActionsInstance = ActionsFactory(Service)
export default {
  getEntries: baseActionsInstance.getEntries,
  getEntry: baseActionsInstance.getEntry,
  postEntry: baseActionsInstance.postEntry,
  putEntry: baseActionsInstance.putEntry,
  deleteEntry: baseActionsInstance.deleteEntry
}
