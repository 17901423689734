import BackendService from '../Core/services/BackendService'
import { getMembers } from '@/modules/Core/helpers/HydraHelper'
import { convertParamsToQuery } from '@/modules/Core/helpers/RequestHelper'
const baseURL = '/assignments'

export default {
  list: (params) => BackendService.get(`${baseURL}` + convertParamsToQuery(params)).then(r => getMembers(r.data)).catch(e => Promise.reject(e)),
  get: (id) => BackendService.get(`${baseURL}/${id}`).then(r => r.data).catch(e => Promise.reject(e)),
  post: (data) => BackendService.post(`${baseURL}`, data).then(r => r.data).catch(e => Promise.reject(e)),
  put: (data) => BackendService.put(`${baseURL}/${data.id}`, data).then(r => r.data).catch(e => Promise.reject(e)),
  delete: (data) => BackendService.delete(`${baseURL}/${data.id}`, data).then(r => r.data).catch(e => Promise.reject(e)),
  confirm: (data) => BackendService.patch(`${baseURL}/${data.id}/confirm`, {}, { headers: { 'Content-type': 'application/merge-patch+json' } }).then(r => r.data).catch(e => Promise.reject(e)),
  decline: (data) => BackendService.patch(`${baseURL}/${data.id}/decline`, {}, { headers: { 'Content-type': 'application/merge-patch+json' } }).then(r => r.data).catch(e => Promise.reject(e)),
  unset: (data) => BackendService.patch(`${baseURL}/${data.id}/unset`, {}, { headers: { 'Content-type': 'application/merge-patch+json' } }).then(r => r.data).catch(e => Promise.reject(e))
}
