import BackendService from '../Core/services/BackendService'
import { getIdFromIri, getMembers } from '@/modules/Core/helpers/HydraHelper'
import { convertParamsToQuery } from '@/modules/Core/helpers/RequestHelper'
const baseURL = '/documents'
export default {
  list: (params) => BackendService.get(`${baseURL}` + convertParamsToQuery(params)).then(r => getMembers(r.data)).catch(e => Promise.reject(e)),
  get: (id) => BackendService.get(`${baseURL}/${id}`).then(r => r.data).catch(e => Promise.reject(e)),
  put: (data) => BackendService.put(`${baseURL}/${getIdFromIri(data['@id'])}`, data).then(r => r.data).catch(e => Promise.reject(e)),
  delete: ({ '@id': id }) => BackendService.delete(`${baseURL}/${getIdFromIri(id)}`).then(r => r.data).catch(e => Promise.reject(e)),
  upload ({ file, assignment, event }, onUploadProgress) {
    const formData = new FormData()
    formData.append('file', file)
    if (assignment) {
      formData.append('assignment', assignment)
    } else if (event) {
      formData.append('event', event)
    } else {
      throw new Error('assignment or event required')
    }
    return BackendService.post(`${baseURL}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress
    }).then(r => r.data).catch(e => Promise.reject(e))
  }
}
