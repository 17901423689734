import Service from '../DocumentsService'
import { ActionsFactory } from '@/modules/Core/helpers/ActionsFactory'
const baseActionsInstance = new ActionsFactory(Service)
export default {
  getEntries: baseActionsInstance.getEntries,
  getEntry: baseActionsInstance.getEntry,
  putEntry: baseActionsInstance.putEntry,
  deleteEntry: baseActionsInstance.deleteEntry,

  uploadFile ({ commit }, entry) {
    commit('SET_LOADING', true)
    return Service.upload(entry, (progress) => {
      commit('SET_PROGRESS', progress)
    })
      .then(data => {
        commit('SET_ENTITY', data)
        commit('SET_LOADING', false)
        return Promise.resolve(data)
      })
      .catch((e) => {
        commit('SET_LOADING', false)
        if (e.response && e.response.data && e.response.data.message) {
          return Promise.reject(e.response.data.message)
        } else {
          return Promise.reject(e)
        }
      })
  }
}
