
export default [
  {
    path: '/login',
    name: 'AuthenticationLogin',
    props: true,
    component: () => import(/* webpackChunkName: "authentication" */ './views/login'),
    meta: {
      hideNavigation: true,
      guest: true
    }
  }
]
