import { trim } from 'lodash'

class CookieService {
  _data = {}
  _cookies = ''
  constructor () {
    this._cookies = document.cookie
  }

  parseCookies () {
    this._data = this._cookies.split(';').map(cookie => {
      const parts = cookie.split('=').map(trim)
      return { key: parts[0], value: [parts[1]] }
    })
  }

  getValue (key) {
    this.parseCookies()
    return this._data[key] || null
  }

  deleteCookies () {
    document.cookie += ';max-age=0;'
  }
}

export default new CookieService()
