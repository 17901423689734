import axios from 'axios'

import { assign } from 'lodash'

axios.defaults.withCredentials = true
class ApiClient {
  install (Vue, options) {
    if (!this.axios) throw new Error('Please create a instance with new ApiClient(options) first.')
    Vue.prototype.$http = this
    return this
  }

  constructor (options) {
    this.options = {
      headers: {}
    }

    assign(this.options, options)
    this.axios = axios.create(this.options)
  }

  setHeader (header, value) {
    this.axios.defaults.headers.common[header] = value
  }

  unsetHeader (header) {
    if (this.axios.defaults.headers.common[header]) {
      delete this.axios.defaults.headers.common[header]
    }
  }

  get (resource) {
    return this.axios.get(resource)
  }

  post (resource, data) {
    return this.axios.post(resource, data)
  }

  put (resource, data) {
    return this.axios.put(resource, data)
  }

  patch (resource, data, options = {}) {
    return this.axios.patch(resource, data, options)
  }

  delete (resource) {
    return this.axios.delete(resource)
  }

  /**
   * Perform a custom Axios request.
   *
   * data is an object containing the following properties:
   *  - method
   *  - url
   *  - data ... request payload
   **/
  customRequest (data) {
    return this.axios(data)
  }
}

export default ApiClient
