import store from '../../../store'
import { getLoggedInUserFromCookie } from '@/modules/Authentication/helper/Cookies'
export const isLoggedIn = () => {
  const data = getLoggedInUserFromCookie()
  return (data && !!data.username) || false
}
export const isSessionExpired = () => {
  const data = getLoggedInUserFromCookie()
  if (!data || !data.exp) return false
  const expTime = new Date(data.exp * 1000).getTime()
  return (data && data.exp && expTime <= new Date().getTime()) || false
}

export const isLoggedInAndSessionNotExpired = () => (isLoggedIn() && !isSessionExpired()) || false
export const isPublicPage = (to) => to.matched.every(record => record.meta.guest) || false
export const isAdmin = (user) => userHasRole(user, 'ROLE_ADMINISTRATOR') || userHasRole(user, 'ROLE_SUPER_ADMIN')
export const isOrgaTeam = (user) => userHasRole(user, 'ROLE_ORGA_TEAM')
export const currentUserIsAdmin = () => isAdmin(currentActiveUser())
export const isPerformer = (user = null) => user ? userHasRole(user, 'ROLE_PERFORMER') : hasRole('ROLE_PERFORMER')
export const isTechnician = (user = null) => user ? userHasRole(user, 'ROLE_TECHNICIAN') : hasRole('ROLE_TECHNICIAN')
export const isTechnicianLead = (user = null) => user ? userHasRole(user, 'ROLE_TECHNICIAN_LEAD') : hasRole('ROLE_TECHNICIAN_LEAD')
export const userHasRole = (user, role) => user?.roles?.indexOf(role) > -1

export const isAdminPage = (to) => to.matched.some(record => record.meta.admin) || false
export const routeHasRight = (to) => to.matched.every(record => !record.meta.requiredRole ||
  hasRole(record.meta.requiredRole))

export const currentActiveUser = () => store?.getters['users/profile']
export const hasRole = (role) => {
  return currentActiveUser()?.roles?.indexOf(role) > -1
}
