import defaultState from './default'
import { keyBy } from 'lodash'
import Vue from 'vue'
import { updateEntities } from '@/modules/Core/helpers/MutationHelper'
import { idOrIriOrObjectAreEqual } from '@/modules/Core/helpers/HydraHelper'
const STATE_KEY = 'assignments'
const KEY = '@id'

export default {
  SET_LOADING (state, flag) {
    state.loading = flag
  },
  SET_ENTITIES (state, { entities, params }) {
    // const listToDeleteIds = []
    const listOfEntities = Object.values(state[STATE_KEY]) || []

    const newList = updateEntities(entities, listOfEntities, (entity) => {
      return (!params) ||
      (params && (
        Object.keys(params).every(key => idOrIriOrObjectAreEqual(entity[key], params[key]))
      ))
    })
    // console.log('MUTIATIONS: vue.set', state[STATE_KEY], keyBy(newList, KEY) || {})

    Vue.set(state, STATE_KEY, keyBy(newList, KEY) || {})
  },
  SET_ENTITY (state, entry) {
    Vue.set(state[STATE_KEY], entry[KEY], entry)
  },
  DELETE_ENTRY (state, entry) {
    Vue.delete(state[STATE_KEY], entry[KEY])
  },

  RESET_STORAGE (state) {
    for (const key in defaultState) {
      state[key] = defaultState[key]
    }
  }
}
